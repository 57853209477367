.footer {
    position: relative;
    padding: 34px 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
        content: '';
        width: 100%;
        position: absolute;
        top: -45px;
        left: 0;
        height: 60px;
        background-image: url("../../../assets/images/linkBgPale.svg");
        background-repeat: repeat-x;
        background-position: -50px 0;
        opacity: 0.7;
        z-index: -2;
    }
}

.footerBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    rotate: 180deg;
}

.buttonSectionMain {
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialMedia {
    display: flex;
    justify-content: center;
    gap: 18px;
    margin-bottom: 20px;

    a {
        display: flex;

        img {
            width: 15px;
            height: 15px;
        }
    }
}

.footerWithSubscribe {
    .socialMedia {
        margin-top: 30px;
    }
}

.footerLogo {
    width: 240px;
}
