@import "src/styles/variables";

.cardList {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    height: 88px;
    z-index: 2;
}

.swiper {
    width: 100%;
    height: 88px;
}

.swiperSlide {
    width: 303px;
    height: 88px;
}

.cardItem {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    background-image: url("../../../assets/images/mapCardBg.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 30px;
    display: flex;
    align-items: center;

    &:hover {
        cursor: grab;
    }

    &:focus {
        cursor: grabbing;
    }
}

.cardItemLogo {
    width: 45px;
    height: max-content;
    margin-inline-end: 20px;
}

.cardText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
}

.cardItemTitle {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 2px;
}

.cardLink {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    color: #ffffff;
    text-decoration: underline;

    &.podcastLink {
        color: $flamePea
    }

    &.kioskLink{
        color:#6D1D46;
    }

    &.storyLink{
        color: $fireBush;
    }
}

.kioskButton {
    margin-bottom: 0;
    font-size: 21px;
    font-weight: 500;
    color: $white;
    margin-inline-end: 20px;
}
