@import "src/styles/variables";

.wrapper {
    border-radius: 50%;
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:global {
        &.height-default {
            width: 50px;
            height: 50px;
        }

        &.height-small {
            width: 34px;
            height: 34px;
        }

        &.default {
            background-color: $summerGreen;
        }

        &.story {
            background-color: $fireBush;
        }

        &.podcast {
            background-color: $flamePea;
        }

        &.kiosk {
            background-color: $fireBush;
        }
    }
}
