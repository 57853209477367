$white: #ffffff;
$black: #000000;
$aluminium: #818a91;
$montana: #373a3c;
$gray: #6c757d;
$new-orange: #eb5027;
$fireBush: #e5942d;
$frenchGray: #cfcfd1;
$mirage: #1b2937;
$rhino: #304961;
$porcelain: #f5f6f7;
$concrete: #f2f2f2;
$summerGreen: #90b9b4;
$flamePea: #df583b;
$alabaster: #f7f7f7;
$tawnyPort: #6e1c46;
$tallPoppy: #b42933;
$top_panel_border_radius: 5px;
$button_border_radius: 3px
