@import 'styles/variables.scss';

.btnWrapper {
  margin-top: 10px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  text-align: center;
}
.button {
  color: $fireBush;
  border: none;
  text-decoration: underline;
}
.settingWrapper{
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 5px;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background:$concrete ;
  padding: 10px;
  color: $concrete
}
.settingIcon{
  width: 20px;
  height: 20px;
  fill: $fireBush;
}
.optionButton {
  border: 1px solid $frenchGray;
  width:70px;
  height: 30px;
}
.optionName{
  color: black;
  text-align: center;
  padding: 5px;
}
.fontSizeBtn{
  font-size: 15px;
    .optionButton:nth-child(2){
      font-size: 15px;
    }
    .optionButton:nth-child(3){
      font-weight: bold;
    }
}

.themeBtn{
  .optionButton{
    position: relative;
    border-bottom: none;
    box-sizing: border-box;
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -1px;
      width: 101%;
      height: 3px;
      background: $fireBush;
    }
    &:nth-child(2){
      border-right: none;
      &::after{
        background: $fireBush;
      }
    }
  }
  
  .optionButtonThemeLight{
    position: relative;
    border: 1px solid $frenchGray;
    border-bottom: none;
    box-sizing: border-box;
    width: 70px;
    border-bottom: none;
    border-right: none;
    box-sizing: border-box;
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -1px;
      width: 101%;
      height: 3px;
      background: $frenchGray;
    }
    &.optionButtonThemeLightActive{
      &::after{
        background: $fireBush;
      }
    }
  }
  .optionButtonThemeDark{
    position: relative;
    border: 1px solid $frenchGray;
    border-bottom: none;
    box-sizing: border-box;
    background: $mirage;
    color: $white;
    width: 70px;
    border-bottom: none;
    border-left: none;
    box-sizing: border-box;
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -1px;
      width: 101%;
      height: 3px;
      background: $frenchGray;
    }
    &.optionButtonThemeDarkActive{
      &::after{
        background: $fireBush;
      }
    }
  }
  
}
.footer{
  background: url('../../assets/images/footerLogo.svg') no-repeat center;
  min-height: 150px;
  margin-top: auto;
  margin-bottom: 30px;
}
.darkTheme {
  position: absolute;
  .body{
    background: $mirage;
    color: $white;
    .footer{
      background: url('../../assets/images/footerLogoLight.svg') no-repeat center;
    }
  }
  .header{
    position: relative;
    background: $fireBush;
  }
  .title, .subtitle {
    color: $mirage
  }
  .date {
    color: $white
  }
  .btnAbout{
    background: $white;
    color: $summerGreen;
  }
  .btnOtherContent{
    background: $summerGreen;
    color: $mirage;

  }
}