@import "src/styles/variables";

.wrapper {
    display: flex;
}

.button {
    position: relative;
    z-index: 2;
    height: 24px;
    width: 72px;
    border: none;
    background-color: transparent;
    padding: 0;
    overflow: hidden;
}

.buttonBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.buttonContent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonText {
    font-size: 12px;
    color: $white;
    margin-right: 5px;
}

.shareButtonsList {
    position: relative;
    display: flex;
    gap: 12px;
    z-index: 1;
    padding-left: 12px;
    transform: translateX(-110%);
    transition: transform 0.2s cubic-bezier(0.18, 1.2, 1, 0.93);

    &.open {
        transform: translateX(0);
    }
}

.shareButton {
    border: none;
    background-color: transparent;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
}

[dir='rtl'] {
    .wrapper {
        flex-direction: row-reverse;
    }

    .buttonContent {
        flex-direction: row-reverse;
    }

    .shareButtonsList {
        flex-direction: row-reverse;
    }
}
