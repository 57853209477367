@import "src/styles/variables.scss";

.wrapper{
  position: relative;
  height: 100vh;
  width: 100%;
}

.content{
  padding: 100px 25px 25px 25px;
  background-color: $porcelain;
  height: 100%;
  width: auto;
  overflow: auto;
  .titleWrapper{
    padding: 20px 0;
    margin: 0;
    text-align: start;
  }
}

.titleText {
  color: $black
}

.open{
  margin-left: 250px;
  transition-duration: 0.5s;
}

.close{
  margin-left: 0;
  transition-duration: 0.5s;
}