@import "src/styles/variables.scss";

.paginationContainer {
  :global {
    .page-item.active .page-link {
      background-color: $mirage;
      color: $white;
    }
  }
  .pageLink {
    color: $mirage;
    box-shadow: unset;
  }
}
