@import "src/styles/fonts.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins";

.loginForm {
  background: $white;
  padding: 30px;
  border-radius: 5px;
  width: 500px;
  min-height: 530px;
  z-index: 1;

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    .logo{
      width: 55%;
      height: 100%;
    }
  }

  .phoneInput{
    background: transparent;
    width: 100%;
  }
  
  p {
    color: $gray
  }

  &Main {
    margin-top: 30px;;

    .formGroup {
      // margin: 30px 0;
      label {
        margin-bottom: 10px;
        @include font-style($color: $montana, $bold: 500);
      }

      input {
        margin-bottom: 10px;
      }

      input.requiredField {
        border: 2px solid $new-orange;
        border-radius: 5px;
      }
    }

    .submitBtn {
      margin-top: 30px;
      width: 100%;
      border-radius: 10px;
      border: none;
      background: $mirage;
      padding: 10px 0;
      @include font-style($size: 16px, $color: $white, $bold: 600);
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .loginForm {
    width: 90%;
  }
}

.error{
  color: red;
  position: absolute;
  bottom: 5px;
  left: 0;
  font-size: 12px;
}