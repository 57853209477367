@import 'styles/variables.scss';

.pageWrapper{
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.footer{
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
  padding-bottom: 50px;
  margin-top: auto;
  padding-top: 10px;
  &::before{
    position:absolute;
    content: '';
    bottom: -160px;
    left: 0;
    width: 100%;
    height: 250px;
    background: url('../../assets/images/footerBg.svg') no-repeat;
    background-size: cover;
  }
  img {
    position: relative;
    z-index: 3;
    cursor: pointer;
    margin-bottom: 34px;
  }
  .buttonSectionMain{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 34px;
    
    .buttonTopSectionMain{
      display: flex;
      gap: 10px;
      justify-content: center;
    }

    .buttonTopSectionSecond{
      margin-bottom: 34px;
    }
  }

}

@media (min-width: 768px){
  .footer {
    margin-top: auto;
    &::before {
      height: 256px;
    }
  }
}
