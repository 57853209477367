@import 'src/styles/variables';

.body {
  padding-top: 70px;
  text-align: center;

  .programsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto 50px;
    max-width: 700px;

    .desc {
      margin-bottom: 20px;
      padding: 0 35px;
    }

    .btn {
      background: $mirage;
      color: $white;
      border: none;
      font-weight: 600;
      padding: 5px 15px;
      cursor: pointer;
      text-decoration: none;
    }

  }
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0.5px;
}

.description {
  padding: 0 35px;
  font-weight: 500;
  max-width: 700px;
  margin: 0 auto 75px;
}

.links {
  display: flex;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 25px;
  height: fit-content;
  justify-content: space-between;
  align-items: flex-start;

  .link {
    width: 70px;
    object-fit: contain;
  }
}

.pageNavigation {
  position: relative;
  background: $alabaster;
  width: 100%;
  z-index: 1;
  padding-top: 20px;

  .linksBg {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    object-fit: cover;
    object-position: top;
  }

  .linksBgTest {
    margin-top: 10px;
    background-size: auto 100%;
    height: 40px;
  }

}

[dir='rtl'] {
  .links {
    flex-direction: row-reverse;
  }

  .title {
    letter-spacing: unset;
  }
}


