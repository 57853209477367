.wrapper {
    padding: 40px 36px 34px;
}

.headerButtons {
    display: flex;
    align-items: center;
    gap: 10px;
}

[dir='rtl'] {
    .headerButtons {
        flex-direction: row-reverse;
    }
}
