.header {
    width: 100%;
    position: relative;
    z-index: 2;

    &:global.normal {
        height: 181px;
    }

    &:global.small {
        height: 121px;
    }
}

.headerBg {
    position: absolute;
    width: 100%;
    height: 100%;
}

.headerSymbolsWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.headerSymbols {
    position: absolute;
    filter: contrast(2);
    overflow: hidden;

    &:global {
        &.normal {
            &.left {
                height: 138px;
                top: 40px;
                left: -23px;
            }

            &.right {
                height: 150px;
                top: 0;
                right: 23px;
            }
        }

        &.small {
            &.left {
                height: 117px;
                top: 0;
                left: -23px;
            }

            &.right {
                height: 101px;
                top: 15px;
                right: 25px;
            }
        }
        &.without {
            &.left {
                height:0;
                top: 0;
                left: -23px;
            }

            &.right {
                height: 0;
                top: 0;
                right: 25px;
            }
        }
    }
}

.languageSelect {
    position: absolute;
    top: 0;
    left: 36px;
}

.bannerWrapper {
    position: absolute;
    top: 0;
    right: 36px;
}

.logoWrapper {
    position: absolute;
    top: 0;
    right: 45px;
    height: 100%;
    display: flex;
    align-items: center;
}

.contentWrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    transform: translateY(45%);
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px;
}

[dir='rtl'] {
    .contentWrapper {
        flex-direction: row-reverse;
    }
}
