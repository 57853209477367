@import "src/styles/variables.scss";

.panelWrapper{
    position: relative;
    z-index: 5;
    width: 100%;
    height: 80px;
    background-color: $white;
    border-radius: $top_panel_border_radius;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-bottom: 20px;
}

.panelCategory{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

}
.panelButtonGroup{
    display: flex;
    justify-content: space-between;
    min-width: 125px;
}
.panelSearch{
    width: 250px;
    min-width: 100px;
    margin-right: 30px;
}
.totalCountContainer{
    display: flex;
    align-items: center;
    label{
        margin: 0px 10px 0px;
    }
.totalCount{
    width: 250px; 
}
}
.panelSelect{
    z-index: 200;
    height: fit-content;
    width: 175px;
    margin: 0px 30px 0px 10px;
}
.panelButton{
    outline: none;
    border: none;
    padding: 6px;
    background-color: $mirage;
    color: $white;
    border-radius: $button_border_radius;
    font-size: 14px;
    transition-duration: .3s;
    &:hover{
        color: $aluminium
    }
}

@media (max-width:1145px) {
    .panelWrapper{
        height: 110px;
    .panelCategory{
        margin-right: 15px;
    }  
}
}
@media (max-width:1035px) {
    .panelWrapper{
        height: 140px;
    .totalCountContainer{
        margin-top: 10px;
        label{
            margin: 0px 10px 0px 0px;
        }
    }
}
}
@media (max-width:669px) {
    .panelWrapper{
        height: 200px;
        .panelSearch{
            margin-top: 10px;
        }
}
}
@media (max-width:388px) {
    .panelWrapper{
        height: 220px;
        .panelSearch{
            width: unset;
            margin-right: unset;
        }
        .panelSelect{
            margin:0px 0px 0px 10px;
        }
        .totalCountContainer{
        .totalCount{
            width: 150px; 
        }
        }
}
}
