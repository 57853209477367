@import "src/styles/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 302px;

    & > * {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-top: 20px;
        }
    }
}

.message {
    text-align: center;
    font-size: 16px;
    margin-bottom: 30px;

    &.error {
        color: #8b0000;
    }

    &.success {
        color: $summerGreen;
    }
}