.formBlockClose{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    .chevron{
        height: 20px;
        width: 20px;
        transition: 0.5s ease-in-out;
    }
    .chevronClose{
        height: 20px;
        width: 20px;
        rotate: 180deg;
        transition: 0.5s ease-in-out;
    }
}
.formBlockOpen{
    cursor: pointer;
    padding-top: 20px;
}