.mapWrapper {
    position: absolute;
    bottom: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.mapContainer {
    width: 100%;
    height: 100%;
}
