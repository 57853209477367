@import "src/styles/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.totalButton {
    background-color: transparent;
    border: none;
    background-image: url('../../assets/images/statisticsTotalBg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 88px;
    // width: 303px;
    padding: 12px 20px;
    margin-bottom: 65px;
}

.totalContent {
    display: flex;
    align-items: center;
}

.totalTitle {
    color: $white;
    font-size: 22px;
    text-align: start;
}

.totalCount {
    font-size: 30px;
    font-weight: 700;
    margin-inline-end: 20px;
    flex-grow: 1;

    &:global {
        &.story {
            color: $fireBush;
        }

        &.podcast {
            color: $flamePea;
        }
    }
}

.itemsWrapper {
    display: grid;
    grid-template: auto / repeat(4, 1fr);
    gap: 60px;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &:nth-of-type(1) .itemCounter:global {
        &.story {
            color: $tallPoppy;
        }

        &.podcast {
            color: $fireBush;
        }
    }


    &:nth-of-type(2) .itemCounter:global {
        &.story {
            color: $flamePea;
        }

        &.podcast {
            color: $tallPoppy;
        }
    }

    &:nth-of-type(3) .itemCounter {
        color: $tawnyPort;
    }

    &:nth-of-type(4) .itemCounter {
        color: $summerGreen;
    }
}

.itemCounter {
    font-size: 33px;
}

.itemTitle {
    font-size: 13px;
}

@media (max-width: 650px) {
    .itemsWrapper {
        grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    }
}
