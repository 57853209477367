.dropdownMenu {
    position: absolute;
    left: 33px;
    top: 66px;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    background-color: #ecf0ef;
    padding: 8px;
    border-radius: 25px;
    border: 0.5px solid grey;

    .firstCircle {
        cursor: pointer;
        background-color: #e38331;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
        }

        &.isActiveCircle {
            background-color: #1b2937;
        }
    }

    .secondCircle {
        cursor: pointer;
        background-color: #df583b;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
        }

        &.isActiveCircle {
            background-color: #1b2937;
        }
    }

    .thirdCircle {
        cursor: pointer;
        background-color: #e5942d;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
        }

        &.isActiveCircle {
            background-color: #1b2937;
        }
    }

    .fourthCircle {
        cursor: pointer;
        background-color: #6D1D46;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
        }

        &.isActiveCircle {
            background-color: #1b2937;
        }
    }

    .activeCircle {
        background-color: #90b9b4;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
        }

        &.isActiveCircle {
            background-color: #1b2937;
        }
    }

    .secondSection {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 4px;
    }
}
