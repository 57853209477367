@import 'src/styles/variables';

.headerImgBg {
    width: 100%;
    min-height: 300px;
    position: fixed;
    left: 0;
    top: 0;
    object-fit: cover;

    &Default {
        top: 185px;
        min-height: 115px;
        width: initial;
    }
}
.languageSelect{
    padding-inline-start: 25px;
}

.pageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    min-height: 100vh;
    background: $white;
}

.header {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
    width: 100%;
    background: url('../../assets/images/headerBg.svg') top left no-repeat;
    background-size: cover;
    padding: 0 35px;
    height: 180px;
    color: $white;
    z-index: 1;
    margin-top: 300px;
    box-shadow: 0 -30px 60px #00000029;

    div {
        width: 100%;
        font-size: 15px;
    }
}

.title {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 15px;
    letter-spacing: 0.5px;
}

.content {
    margin-top: 15px;
}

.date {
    margin-top: 10px;
    color: $fireBush;
}

.link {
    width: 100%;
    display: block;
    color: $fireBush;
    font-size: 15px;
    font-weight: bold;

    &:hover {
        color: $fireBush;
    }
}

.body {
    position: relative;
    background: $white;
    padding: 10px 30px 20px;
    color: $mirage;
    font-size: 15px;

    .title {
        font-weight: bold;
        font-size: 15px;
        padding-top: 50px;
    }
}

.bodySize_1 {
    font-size: 18px;
}

.bodySize_2 {
    font-size: 20px;
}

.bodySize_3 {
    font-size: 22px;
}

.bodySize_4 {
    font-size: 24px;
}

.buttonsWrapper {
    width: 80%;
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 3;

    a, button {
        border: none;
        height: 35px;
        text-decoration: none;
        line-height: 35px;
    }
}

.btnAbout {
    margin-bottom: 10px;
    background: $summerGreen;
    font-weight: 600;
    color: $mirage;
    text-align: center;
    background: url("../../assets/images/btnAboutContent.svg") 50% 50% no-repeat;
    background-size: 100%;
    width: 100%;
    max-width: 300px;

    &:hover {
        color: $mirage;
    }
}

.btnOtherContent {
    background: $mirage;
    color: $white;
    font-weight: 600;
    background: url("../../assets/images/btnReadOther.svg") 50% 50% no-repeat;
    background-size: 100%;
    width: 100%;
    max-width: 300px;
}

.progressWrapper {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 70px;
    background: $concrete;
    z-index: 3;
}

.activeSettingWrapper {
    height: 100px;
}

.shareButtonWrapper {
    position: relative;
    z-index: 2;
}

.footer {
    position: relative;
    text-align: center;
    min-height: 150px;
    margin-top: auto;
    margin-bottom: 20px;
    background: $white;

    .footerLogo {
        height: 25px;
        margin-top: 26px;
        position: relative;
        z-index: 2;
        cursor: pointer;
    }

    .bgImg {
        position: absolute;
        top: -37px;
        width: 450px;
        left: 0;
        z-index: 1;
    }
}

.darkTheme {
    position: absolute;
    width: 100%;
    background-color: $mirage;

    .body {
        background: $mirage;
        color: $white;
    }

    .footer {
        background: $mirage;
    }

    .header {
        position: relative;
        background: $fireBush;
    }

    .title, .subtitle {
        color: $mirage
    }

    .date, .link {
        color: $white
    }

    .btnOtherContent {
        background: $white;
        color: $summerGreen;
    }
}

@media (min-width: 768px) {
    .headerImgBgDefault {
        top: 44px;
        min-height: 256px;
    }
    .btnAbout {
        margin: 10px auto;
        width: 250px;
    }
    .btnOtherContent {
        margin: 0 auto;
        width: 250px;
    }
    .buttonsWrapper {
        flex-direction: row;
        align-items: center;
    }
    .settingWrapper {
        justify-content: center;
    }
    .fontSizeBtn {
        margin-right: 50px;
    }
    .footer {
        .bgImg {
            height: 256px;
        }
    }
}

@media (max-width: 375px) {
    .darkTheme {
        .btnAbout {
            background: $white;
            color: $summerGreen;
        }

        .btnOtherContent {
            background: $summerGreen;
            color: #000000;
        }
    }
}

[dir='rtl'] {
    .formTittle {
        padding-inline-start: 10px
    }
}


[dir='rtl'] {
    .languageSelect{
        padding-inline-end: 25px;
    }
    
    .title {
        letter-spacing: unset;
    }
}
