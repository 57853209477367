@import "src/styles/variables";

.inputControl {
    width: 100%;
    height: max-content;
}

.inputWrapper {
    position: relative;
    width: 100%;
    height: 40px;

    &.error, &:focus-within.error {
        .inputBorder svg {
            fill: #8b0000;
        }

        .inputLabel {
            color: #8b0000;
        }
    }

    &:focus-within {
        .inputBorder svg {
            fill: $black;
        }

        .inputLabel {
            color: $black;
        }
    }
}

.inputBorder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    svg {
        fill: #999999;
        width: 100%;
        height: 100%;
    }

    &:global.secondary {
        transform: rotate(180deg);
    }
}

.inputLabel {
    position: absolute;
    top: -13px;
    left: 10px;
    background-color: $white;
    font-size: 14px;
    color: #212529;
}

.inputContent {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10px;
}

.input {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 14px;
    flex-grow: 1;
    padding: 0;
    color: #212529;

    &:focus {
        outline: none;
    }
}


.startContent {
    font-size: 14px;
    margin-inline-end: 5px;
    color: #212529;
}

.errorText {
    color: #8b0000;
    font-size: 12px;
}

[dir='rtl'] {
    .inputLabel {
        top: -15px;
        left: auto;
        right: 10px;
    }
}
