.footer {
    position: relative;
}

.footerBgWrapper {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 185px;
}

.footerBg {
    position: absolute;
    left: 0;
    width: 100%;
}

.footerContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 34px;
    padding-bottom: 34px;
}

.footerLogo {
    width: 240px;
}
