@import 'styles/variables.scss';

.header{
  background: url('../../assets//images/publisherBg.svg') no-repeat;
  background-size: cover;
  text-align: center;
  padding-top: 70px;
  color: $white;
  padding-bottom: 50px;
  height: auto;
  .title {
    text-align: center;
    color: $white;
    letter-spacing: 0.5px;
  }
}
.publisherPhoto{
  justify-content: center;
  width: 100%;
  height: 150px;
  img{
    width: 145px;
    height: 145px;
    background: #ffffff;
  }
}
.darkLogo{
  img{
    background: #141F2A!important
  }
}
.linkBtn{
  background: url('../../assets/images/buttonYellowBg.svg') no-repeat;
  background-size: contain;
  border: none;
  width: 160px;
  color: $white;
  height: 35px;
  margin: 0 auto;
  transform: translateY(65px);
}
.podcastLinkBtn {
  background: url('../../assets/images/townyPortBtnBg.svg') no-repeat;
  background-size: contain;
}
.publisherName{
  text-align: center;
}
.body {
  padding: 0 30px;
  padding-top: 45px;
  text-align: left;
}
.title {
  color: $mirage;
  text-align: start;
  font-weight: bold;
}
.subtitle{
  color:$frenchGray
}
.footer{
  min-height: 115px;
  background: url('../../assets/images/footerLogo.svg') no-repeat center;
  margin-top: auto;
}
.pageWrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: right;
}
.publisherInfo {
  padding: 35px;
  text-align: left;
}
.article {
  margin: 20px 0;
  .title {
    text-align: right;
  }
  .description {
    margin-top: 5px;
    color: $mirage
  }
}
.quote{
  color:$flamePea;
  text-align: right;
}
.podcastInfo{
  display: flex;
  width: 300px;
  height: 70px;
  margin: 0 auto;
  justify-content: space-between;
  img{
    width: 30px;
    height: 30px;
  }

}
.item {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-between ;
}
.itemSubtitle{
  color: $white;
  font-weight: bold;
}
.podcastDescription{
  color: $tawnyPort
}
[dir="rtl"]{
  .body {
    text-align: right;
  }
  .publisherInfo{
    text-align: right;
  }
}
@media (max-width: 500px) {
  .podcastInfo {
    width: 200px;
    margin: 0 auto;
  }
}

[dir='rtl']{
  .title {
    letter-spacing: unset;
  }
}