.mainContainer{
    display: flex;
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    justify-content: center;
    align-items:center;
    background-color: rgba(0,0,0,0.3);

    .modalContainer{
        background-color: #FFFFFFFF;
        color: #75797d;
        width: 400px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-weight: 500;
        border-radius: 4px;

        @media (max-width:430px) {
            width:250px
        }

        .header{
            width: 350px;
            display: flex;
            justify-content: center;
            position: relative;

            @media (max-width:430px) {
                width:100px
            }
            i{
                position: absolute;
                right: 20px;
                top:20px;
                transform: scale(0.8);
                cursor: pointer;

                @media (max-width:430px) {
                    right: -20px;
                    top:25px;
                }
            }

            .title{
                margin: 25px 0px;
                }
        }

        .buttons{
            display: flex;
            gap:30px;
            margin: 20px;

            button{
                outline: none;
                border: none;
                padding: 5px 14px;
                cursor: pointer;
                color: #FFFFFFFF;
                background: #6d757d;
                border-radius: 3px;
              
                &:hover{
                     background-color: #000000; 
                }
            }
        }

        .line{
            height: 1px;
            width: 350px;
            background-color: #e6e9ec;
              
            @media (max-width:430px) {
                width:100px
            }    
        }
    }  
}

