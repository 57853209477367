@import "src/styles/variables.scss";
@import "src/styles/mixins";

.newAdminFormWrapper {
    background: $white;
    border-radius: $top_panel_border_radius;
    padding: 10px 10px 10px 20px;
    form{
        display: flex;
        flex-direction: column;
    }
}

.submitBtn {
    align-self: flex-end;
    padding: .375rem .75rem;
    border-radius: $button_border_radius;
    border: none;
    background: $mirage;
    @include font-style($size: 14px, $color: $white, $bold: 600);
}

.error{
    color: red;
    position: absolute;
    bottom: -10px;
    left: 0;
    font-size: 12px;
}