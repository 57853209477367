@import "src/styles/fonts.scss";
@import "src/styles/variables.scss";
@import "src/styles/mixins";

.adminLoginPage {
    min-height: 100vh;
    background: linear-gradient(180deg,$rhino,$mirage);
    @include display-flex(
    $justify: space-between,
    $align: center,
    $direction: column
  );
  
    position: relative;
  
    &::before {
        content: "";
        @include square(100%);
        @include position($position: absolute, $top: 0, $left: 0);
        background: url("../../assets/images/bg-pattern.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
  
    .copyright {
        @include font-style($size: 16px, $color: $white, $bold: 500);
        margin-bottom: 20px;
  }
}