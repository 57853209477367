@import 'styles/variables.scss';

.playerWrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.controllMenu {
  display: flex;
  width: 200px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}
.next {
  background: url('../../assets/images/nextButton.svg') no-repeat;
  width: 20px;
  height: 20px;
  border: none;
  transform: rotate(180deg);
  margin-left: 10px;
}

.play, .pause {
  background-size: contain;
  width: 50px;
  height: 50px;
  border: none;
  transform: translateY(-70px);
  align-self: flex-end;
  border-radius:50% ;
}
.pause {
  background: $flamePea url('../../assets/icons/pause.svg') no-repeat center;
}

.play {
  background: url('../../assets/images/playButton.svg') no-repeat;
}


.info{
  color:$mirage;
  font-size: 12px;
}
.progressWrapper{
  width: 100%;
  border: 0;
  position: relative;
}
.progress {
  appearance: none;
  cursor: pointer;
  width: 100%;
  height: 4px;
  background: $frenchGray;
  &::before {
    content: '';
    height: 5px;
    width: var(--seek-before-width);
    background: $flamePea;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 13px;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }
  // &[value]::-webkit-progress-value{
  //   // background:$flamePea;
  // }
  &::-webkit-slider-thumb {
    // -webkit-appearance: none;
    all:unset;
    -webkit-appearance: none;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: transparent;
    cursor: pointer;
  }
  &::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    // background: $flamePea;
    // border-color: transparent;
    // color: transparent;
  }
}
.infoWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-start;
}
.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  span {
    color: $flamePea;
    font-weight: bold;
  }
}
[dir='rtl']{
  .next{
    transform: rotate(0deg);
    margin-right: 30px;
  }
  .progress {
    &::before {
      right: 0;
      left: unset;
    }
  }
  .info{
    direction: ltr;
  }
}