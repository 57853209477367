@import "src/styles/variables.scss";

[dir='rtl'] {
    #root {
        font-family: "EffraAr", sans-serif !important;
    }
}


#root {
    height: 100%;
    font-family: "Effra", sans-serif;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.App-table-default-wrapper {
    background-color: $white;
    border-radius: $top_panel_border_radius;
    padding: 1.5rem;
    overflow-x: auto;

    table {
        min-width: 700px;
        border: none;

        thead {
            th {
                border: none;
                text-align: center;
                vertical-align: middle;
            }

            background-color: $porcelain;
        }

        td {
            text-align: center;
            vertical-align: middle;
            border: none;
        }
    }
}

.App-table-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.App-table-icon {
    cursor: pointer;
}

.admin-required-field {
    color: #8b0000;
}
