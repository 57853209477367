@import "src/styles/variables.scss";
@import "src/styles/mixins";

.newAdminFormWrapper {
    form{
        display: flex;
        flex-direction: column;
    }
}

.submitBtn {
    align-self: flex-end;
    padding: .375rem .75rem;
    border-radius: $button_border_radius;
    border: none;
    background: $mirage;
    @include font-style($size: 14px, $color: $white, $bold: 600);
}

.linkWrapper { 
    background-color: #e9ecef;
    a{
        color: black;
        :hover{
            color: black;
        }
    }
}

.error, .slateError{
    color: red;
    position: absolute;
    bottom: -8px;
    right: 10px;
    font-size: 14px;
}

.slateError{
    bottom: -20px;
}

.formBlockWrapper{
    background: $white;
    padding: 10px 10px 10px 20px;
    margin-bottom: 20px;
    border-radius: $top_panel_border_radius;
}