@import "src/styles/variables";

.form {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.select {
    width: 200px;

    &.selectError {
        [class$="-control"] {
            border-color: #8b0000;
        }
    }

}

.input {
    width: 250px;

    &.inputError {
        border-color: #8b0000;
    }
}

.exportButton {
    border: none;
    outline: none;
    padding: 6px;
    border-radius: $button_border_radius;
    font-size: 14px;
    margin-left: 15px;
    background-color: $summerGreen;
    color: $mirage;
    transition-duration: 0.3s;

    &:hover {
        color: $rhino
    }
}

.formGroup {
    display: flex;
    align-items: flex-start;
}

.formLabel {
    margin-right: 10px;
    margin-top: 7px;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.error {
    color: #8b0000;
    font-size: 12px;
}
