.dropdownMenuWrapper{
    :global{

        .dropdown-menu.show{
            transform: translate3d(19px, 70px, 0px) !important;
        }

        .dropdown-toggle.btn.btn-secondary{
            background-color: transparent;
            border: none;
            outline: hidden;
            &:focus{
                box-shadow: unset;
            }
        }
    }
}