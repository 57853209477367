@import "src/styles/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
}

.title {
    font-size: 25px;
    color: $mirage;
    margin-bottom: 32px;
    font-weight: 700;
    text-align: center;
}

.list {
    margin-bottom: 20px;
}
