@import "src/styles/variables.scss";

.left_side_menu{
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  width: 250px;
  display: flex;
  justify-content: center;
  box-shadow: -5px 1px 15px 0px rgb(0,0,0,0.66);
}

.side_menu_items{
  list-style: none;
  padding: 0;
  width: 100%;
  justify-self: flex-start;
  padding: 10px;
  p {
    text-align: start;
    padding: 0;
    margin-bottom: 20px;
  }
}

.menu_item{
  text-decoration: none;
  transition-duration: 0.3s;
  color: $aluminium;
  &:hover{
    color: $fireBush
  }
  .icon{
    margin-right: 10px;
  }
}

.activeLink {
  color: $fireBush;
}

.open{
  transform: translateX(-100%);
  transition-duration: 0.5s;
}

.close{
  transform: translateX(0);
  transition-duration: 0.5s;
}