@import "src/styles/variables";

.pageWrapper {
    height: 100%;
    min-height: auto;
}

.headerRightContent {
    display: flex;
    gap: 12px;
}    

[dir='rtl'] {
    .headerRightContent {
        flex-direction: row-reverse;
    }
}

