.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inner {
    position: relative;
    width: 338px;
    height: 560px;
    margin-bottom: 26px;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 21px 18px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    align-self: flex-start;
    margin-bottom: 25px;
}

.spinnerWrapper {
    margin-top: 50px;
}
