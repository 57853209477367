@import 'src/styles/variables';

.btn {
    background: url('../../assets/images/buttonBlue.svg') no-repeat;
    background-size: cover;
    width: 146px;
    height: 35px;
    outline: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        color: $mirage;
    }

    &.wide {
        width: 301px;
        font-size: 13px;

        &:global.primary {
            background: url('../../assets/images/buttonBlueWideBg.svg') no-repeat;
        }
    }

    &:global.secondary {
        background: url('../../assets/images/buttonYellowBg.svg') no-repeat;
        background-size: cover;
    }

    &:global.third {
        background: url('../../assets/images/btnRed.svg') no-repeat;
        background-size: cover;
    }
}