@import "src/styles/variables";

.wrapper {
    width: 100%;
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    gap: 15px;
    justify-content: center;
}

.cardWrapper {
    position: relative;
    height: 88px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;

    &:hover {
        .cardBackground {
            svg {
                fill: $summerGreen;
            }
        }

        .cardTitle {
            color: $white
        }

        .cardSubtitle {
            color: $mirage;
        }
    }
}

.cardBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    svg {
        width: 100%;
        height: 100%;
    }

    &:global {
        &.variant1 {
            transform: rotateX(-180deg) rotateY(-180deg);
        }

        &.variant2 {
            transform: rotateX(-180deg);
        }

        &.variant3 {
            transform: rotateX(-180deg) rotateY(180deg);
        }
    }
}

.cardContent {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5px 20px;
}

.cardTitle {
    font-size: 20px;
    color: $mirage;
    font-weight: 700;
    text-align: start;
    line-height: 0.9;
    margin-bottom: 10px;
}

.cardSubtitle {
    font-size: 11px;
    color: $fireBush;
}
