.languageSelectWrapper {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
}

.languageSelect {
    background-image: url("../../../assets/images/bookmarkWhite.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    width: 45px;
    height: 65px;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    cursor: pointer;


    &:global.story {
        background-image: url("../../../assets/images/bookmark.svg");
    }

    &:global.podcast {
        background-image: url("../../../assets/images/bookmarkRed.svg");
    }
    &:global.map {
        background-image: url("../../../assets/images/bookmark-dark-blue.svg");
        color: white;
    }
    &.french{
        height: 80px;
    }
}

.textWrapper {
    display: flex;
    align-items: center;

    .title {
        font-weight: 700;
    }

    .arrowIcon {
        color: #1b2937;
        font-size: 14px;
        position: relative;
    }
}


.stripsWrapper {
    cursor: pointer;
}

.stripsItem {
    background-image: url("../../../assets/images/PaleBluePath.svg");
    background-repeat: no-repeat;
    background-position: center;
    font-size: 9px;
    width: 44px;
    height: 16px;
    align-items: center;
    text-align: center;
    display: flex;
    color: white;
    z-index: 1;
    margin-bottom: 3px;
    padding-left: 3px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

[dir='rtl'] {
    .languageSelectWrapper {
        flex-direction: row-reverse;
    }

    .textWrapper {
        flex-direction: row-reverse;
    }

    .title {
        position: relative;
        bottom: 3px;
    }

    .stripsItem {
        justify-content: flex-end;
    }
}
