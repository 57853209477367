@import 'src/styles/variables';

.headerImgBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    object-fit: cover;
    z-index: -10;

    &Default {
        top: 185px;
        height: 115px;
        width: initial;
    }
}

.header {
    width: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    margin-top: 300px;

    .infoWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 140px;
        padding: 20px 36px;
        background: $mirage;
        text-align: start;
    }
}

.body {
    margin-bottom: 20px;
    padding-top: 45px;
    position: relative;
    background: $white;

    &::after {
        content: '';
        display: block;
        position: absolute;
        height: 115px;
        width: 100%;
        background: #ffffff;
        bottom: -115px;
    }

    .categoriesButton {
        margin-top: 40px;
        background: #b42933;
        max-width: 301px;
        width: 100%;
        outline: unset;
        border: unset;
        padding: 9px 15px 8px;
        background: url("../../assets/images/redButton.svg") 50% 50% no-repeat;
        font-weight: 600;
        text-align: center;
        background-size: 100%;

        a {
            color: #ffffff;
            text-decoration: unset;
        }
    }
}

.title {
    font-weight: bold;
    font-size: 25px;
    color: $white;
    letter-spacing: 0.5px;
}

.subtitle {
    font-size: 15px;
    color: $white;
    justify-self: flex-end;
}

.link {
    color: $flamePea;
    text-decoration: underline;
    font-size: 15px;
    font-weight: bold;

    &:hover {
        color: $flamePea;
    }
}

.playerWrapper {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.controllMenu {
    display: flex;
    width: 200px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
}

.next {
    background: url('../../assets/images/nextButton.svg') no-repeat;
    width: 20px;
    height: 20px;
    border: none;
    transform: rotate(180deg);
    margin-left: 10px;
}

.play {
    background: url('../../assets/images/playButton.svg') no-repeat;
    background-size: contain;
    width: 50px;
    height: 50px;
    border: none;
    transform: translateY(-70px);
    align-self: flex-end;
}

.info {
    color: $mirage;
    font-size: 12px;
}

.progressWrapper {
    width: 100%;
    border: 0;

}

.infoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-start;
}

.buttonWrapper {
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    margin-left: auto;
    cursor: pointer;

    span {
        color: $flamePea;
        font-weight: bold;
        white-space: nowrap;
        letter-spacing: 0.5px;
    }
}

.shareButtonWrapper {
    position: relative;
    z-index: 2;
}

[dir='rtl'] {
    .next {
        transform: rotate(0deg);
        margin-right: 30px;
    }

    .buttonWrapper {
        margin-left: unset;
        margin-right: auto;
    }

    .title {
        letter-spacing: unset;
    }

    .buttonWrapper {
        span {
            letter-spacing: unset;
        }
    }
}

@media (min-width: 768px) {
    .headerImgBgDefault {
        top: 44px;
        min-height: 256px;
    }
}

@media (max-width: 500px) {
    .header {
        .infoWrapper {
            height: 180px;
        }
    }
    .body {
        .categoriesButton {
            max-width: 250px;
        }
    }
}