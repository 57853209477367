@import 'styles/variables.scss';

  .progress {
    position: absolute;
    appearance: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: $frenchGray;
    &[value]::-webkit-progress-value{
      background:$fireBush;
    }
  }

