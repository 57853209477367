@import "src/styles/variables";

.wrapper {
    width: 100%;
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    gap: 15px;
    justify-content: center;
}

.cardWrapper {
    position: relative;
    height: 88px;
    width: 100%;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
}

.cardBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cardImage {
    &:global {
        &.story {
            width: 53px;
        }

        &.podcast {
            min-width: 67px;
        }
    }
}

.cardContent {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

.cardText {
    margin-inline-start: 20px;
}

.cardTitle {
    font-size: 25px;
    color: $white;
}

.cardSubtitle {
    font-size: 12px;
    color: $white;
    text-align: start;

    &:global {
        &.story {
            color: $fireBush;
        }

        &.podcast {
            color: $flamePea;
        }
    }
}

@media (max-width: 740px) {
    .wrapper {
        grid-template: repeat(4, 1fr) / minmax(260px, 302px);
    }
}
