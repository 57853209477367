.player{
    flex-grow: 1;
    height: 40px;
    margin-right: 10px;
}

.label{
    width: 100px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .input{
        visibility: hidden;
        text-align: center;
        height: 0;
        width: 0;
    }
}
.file_name{
    flex-grow: 1;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    h5{
        margin: 0;
    }
}