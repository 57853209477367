@import "src/styles/variables.scss";

.topbar {
  background-color: $mirage;
  height: 100px;
  z-index: 10000;
  color: $white;

  display: flex;
  justify-content: space-between;

  padding: 0 20px;

  position: fixed;
  left: 0;
  right: 0;

  .sideBarBtnWrapper{
    button{
      display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      color: $white;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
      background-color: transparent;
      padding: .375rem .75rem;
      font-size: 1rem;

      border: none;
      outline: none;
    }

    display: flex;
    align-items: center;
  }

  .dropdownWrapper{
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}