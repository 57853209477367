@import "src/styles/variables";

.buttonWrapper {
    display: flex;
    justify-content: center;
    height: 35px;
    position: relative;
    border: none;
    background-color: transparent;
    padding: 8px 13px;

    &:global {
        &.fixedDefault {
            min-width: 146px;
        }

        &.fixedLarge {
            min-width: 302px;
        }

        &.fixedSmall {
            padding: 8px 31px;
        }
    }

    &:hover {
        .text {
            color: $mirage;
        }
    }
}

.buttonBg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    svg {
        width: 100%;
        height: 100%;
    }

    &:global {
        &.default {
            svg {
                fill: $summerGreen;
            }
        }

        &.story {
            svg {
                fill: $fireBush;
            }
        }

        &.podcast {
            svg {
                fill: $flamePea;
            }
        }
    }
}

.buttonContent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    color: $white;
    font-size: 16px;

    &:global {
        &.default {
            font-size: 16px;
        }

        &.small {
            font-size: 13px;
        }
    }
}

.rightIcon {
    margin-inline-end: 10px;

    img {
        max-height: 25px;
    }
}
