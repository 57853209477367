@import "src/styles/variables.scss";

.panelWrapper{
    width: 100%;
    height: 80px;
    background-color: $white;
    border-radius: $top_panel_border_radius;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 20px;

    margin-bottom: 20px;
}

.panelButton{
    outline: none;
    border: none;
    padding: 6px;
    border-radius: $button_border_radius;
    font-size: 14px;
    &Main{
        transition-duration: .3s;
        background-color: $mirage;
        color: $white;
        &:hover{
            color: $aluminium
        }
    }
    &Export{
        margin-left: 15px;
        background-color: $summerGreen;
        a{
            transition-duration: .3s;
            text-decoration: none;
            color: $mirage;
        }
        &:hover{
            a{
                color: $rhino
            }
        }
    }
}

.panelButtonExportActive{
    background: #90b9b4;
    margin-left: 16px;
}

.panelButtonExportDisactive{
    background: #ECC29B;
    margin-left: 16px;
}