@import "src/styles/variables";

.content {
    padding: 76px 36px 100px;
}

.title {
    margin-bottom: 50px;
    font-size: 16px;
}
